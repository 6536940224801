<template>
  <section>
    <v-container>
      <div>
        <v-btn @click="doGoBackToAllTransaction" color="primary">
          <font-awesome-icon :icon="['fass', 'backward-step']"/>
          <span class="ml-2">All Transactions</span>
        </v-btn>
      </div>
      <div class="text-center mt-16" v-if="isLoading">
        <v-progress-circular
          :size="100"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-else class="mt-4">
        <v-row>
          <v-col>
            <v-card style="height: 100%;">
              <v-card-title>
                <v-row>
                  <v-col>
                    <span>Transaction</span>
                  </v-col>
                  <v-col class="d-flex justify-end">
                    <v-chip class="font-weight-bold mr-2">
                      ${{ transaction.cents / 100 }}
                    </v-chip>
                    <v-chip class="font-weight-bold" :color="statusColor">
                      {{ statusTitle }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-list>
                  <v-list-item>
                    <v-list-item-title>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <font-awesome-icon :icon="['fas', 'cart-shopping']"/>
                            <span class="ml-2 font-weight-bold text-uppercase">#{{ transaction.uuid.substr(-6) }}</span>
                          </span>
                        </template>
                        <span class="font-weight-bold text-uppercase">{{ transaction.uuid }}</span>
                      </v-tooltip>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <font-awesome-icon :icon="['fas', 'calendar-days']"/>
                            <span class="ml-2 font-weight-bold">{{ $moment(transaction.createdAt).format('LLL') }}</span>
                          </span>
                        </template>
                        <span class="font-weight-bold">Transaction was processed on {{ $moment(transaction.createdAt).format('LLL') }}</span>
                      </v-tooltip>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <font-awesome-icon :icon="['fab', 'cc-stripe']"/>
                            <span class="ml-2 font-weight-bold">{{ transaction.paymentIntentId }}</span>
                          </span>
                        </template>
                        <span class="font-weight-bold">Stripe Payment ID</span>
                      </v-tooltip>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <font-awesome-icon :icon="['fas', 'credit-card']"/>
                            <span class="ml-2 font-weight-bold">{{ transaction.paymentBrand.toUpperCase() }} {{ transaction.paymentLastFour.toUpperCase() }}</span>
                          </span>
                        </template>
                        <span class="font-weight-bold">Customer used a {{ transaction.paymentBrand.toUpperCase() }} ending in {{ transaction.paymentLastFour.toUpperCase() }}</span>
                      </v-tooltip>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-card flat>
                  <v-card-title>
                    <span>Purchased products</span>
                  </v-card-title>
                  <v-card-text>
                    <div style="height: 300px; width: 100%; overflow-x: hidden; overflow-y: scroll; border: solid thin;" class="rounded-lg px-3">
                      <div class="d-flex" v-for="(product, index) in this.transaction.products" :key="index" style="border-bottom: solid thin;">
                        <div class="flex-grow-1">
                          <v-list>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>
                                  <span class="font-weight-bold">{{ product.title }}</span>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <span class="font-weight-bold">{{ product.quantity }}</span> x ${{ product.cents / 100 }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="product.quantity >= 2">
                                  Total cost ({{ product.quantity }}x): ${{ (product.quantity * product.cents) / 100 }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                  Product Type: <span class="text-uppercase font-weight-bold">{{ product.type }}</span>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                        <div style="width: 50px;" class="mr-5">
                          <div class="text-center mt-3">
                            <span class="caption font-weight-bold">Fulfilled</span>
                            <span v-if="!isLoadingProducts">
                            <v-checkbox color="success" class="ml-3" outlined off-icon="fa-regular fa-square" v-model="product.fulfilled" @change="doFulfillProduct(product, $event)"></v-checkbox>
                          </span>
                            <span v-else>
                            <v-progress-circular class="ma-7" :size="20" :width="2" color="primary" indeterminate></v-progress-circular>
                          </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card style="height: 100%;">
              <v-card-title>
                Customer
              </v-card-title>
              <v-card-text>
                <v-list>
                  <v-list-item>
                    <v-list-item-title>
                      <font-awesome-icon :icon="['fas', 'signature']"/>
                      <span class="ml-2">{{ transaction.customer.fullName }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>
                      <font-awesome-icon :icon="['fas', 'envelope']"/>
                      <span class="ml-2">{{ transaction.customer.email }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>
                      <font-awesome-icon :icon="['fas', 'phone']"/>
                      <span class="ml-2">{{ formatPhoneNumber(transaction.customer.phoneNumber) }}</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>
                      <font-awesome-icon :icon="['fas', 'fingerprint']"/>
                      <span class="ml-2">
                    {{ transaction.customer.sessionUuid }}
                    <div class="caption">(Tracking UUID | Used for Fraud detection)</div>
                  </span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>
                      <div v-if="!isLoadingCustomerContact">

                        <div class="ml-2">
                          <v-checkbox color="success" label="Contacted Customer" outlined off-icon="fa-regular fa-square" v-model="transaction.contactedCustomer" persistent-hint hint="Has the customer been contacted by staff ?" @change="doFulfillContact($event)"></v-checkbox>
                        </div>
                        <div v-if="customerContactSatisfaction" class="mt-2">
                          <font-awesome-icon :icon="['fas', 'face-frown-open']" class="error--text" size="xl"/>
                          <span class="ml-2 error--text">The customer has not been <br> marked as contacted for 2+ days!</span>
                        </div>
                      </div>
                      <div v-else>
                        <v-progress-circular :size="40" :width="2" color="primary" indeterminate></v-progress-circular>
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-card style="position: absolute; left: 0; bottom: 0;" flat>
                  <v-card-title>
                    Customer Actions
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col class="d-flex justify-center">
                        <v-btn block @click="doEmailTransaction" color="primary" :loading="isLoadingEmail">
                          Email order Receipt
                        </v-btn>
                      </v-col>
                      <v-col class="d-flex justify-center">
                        <v-btn block color="error" @click="doOpenRefundOptions" :disabled="transaction.maxRefundCents === 0 || userRole === 'employee'">
                          Refund options
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card style="height: 100%;">
              <v-card-title>
                Notes
              </v-card-title>
              <v-card-text>
                <div style="width: 100%; height: 400px; border: solid thin; overflow-y: scroll; overflow-x: hidden; flex-direction: column-reverse;" class="rounded-lg mb-2">
                  <div v-if="transaction.notes === null" class="text-center title mt-5">No notes created</div>
                  <div v-else>
                    <div v-for="(note, index) in transaction.notes" :key="index" class="py-2" style="border-bottom: solid thin grey;">
                      <div class="d-flex">
                        <div style="width: 100px;">
                          <div class="text-center" style="border-right: solid thin grey; width: 100px;">
                            <div class="ma-2">
                              <v-avatar color="secondary">
                                {{ note.author.firstName.charAt(0) }}
                                {{ note.author.lastName.charAt(0) }}
                              </v-avatar>
                            </div>
                            {{ $moment(note.createdAt).format('lll') }}
                          </div>
                        </div>
                        <div class="flex-grow-1">
                          <p class="ml-2">{{ note.note }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="">
                  <v-form @submit.prevent="doFulfillNoteAddition" ref="noteForm">
                    <div class="d-flex">
                      <div class="flex-grow-1">
                        <v-textarea outlined rows="3" v-model="note" :rules="inputs.rules.simplyRequired" :disabled="isLoadingNotes" hint="The note will not be formatted."></v-textarea>
                        <v-btn block color="primary" type="submit" :loading="isLoadingNotes">Post Note</v-btn>
                      </div>
                    </div>
                  </v-form>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="transaction.refunds.length >= 1">
            <v-card style="height: 100%;">
              <v-card-title>
                Refunds ( {{ transaction.refunds.length }} )
              </v-card-title>
              <v-card-text>
                <div v-for="(refund, index) in transaction.refunds" :key="index">
                  <v-card>
                    <v-card-text>
                      <div>
                        <div class="d-flex">
                          <div class="title" style="width: 200px;">
                            <span class="mr-2">${{ refund.cents / 100 }}</span>
                            <v-chip class="font-weight-bold">
                              {{ refund.refundStatus }}
                            </v-chip>
                          </div>
                          <div class="flex-grow-1 font-weight-bold">
                            {{ refund.reason }}
                          </div>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <view-refund-options ref="refundOptions" @doLoad="doLoad(transaction.uuid)"></view-refund-options>
    </v-container>
  </section>
</template>

<script>
import ViewRefundOptions from '@/views/admin/transactions/viewRefundOptions.vue'

export default {
  name: 'viewTransaction',
  components: { ViewRefundOptions },
  data: () => ({
    isLoading: false,
    isLoadingProducts: false,
    isLoadingCustomerContact: false,
    isLoadingNotes: false,
    isLoadingEmail: false,
    userRole: null,
    transaction: {
      uuid: null,
      paymentIntentId: null,
      clientSecret: null,
      customerId: null,
      customer: {
        firstName: null,
        lastName: null,
        fullName: null,
        email: null,
        phoneNumber: null
      },
      products: [],
      refunds: [],
      cents: 0,
      paymentStatus: null,
      contactedCustomer: false,
      notes: null,
      createdAt: null,
      maxRefundCents: null
    },
    note: null
  }),
  methods: {
    doLoad (uuid) {
      this.isLoading = true

      this.$services.transaction.doReadInternal(uuid).then(transaction => {
        this.transaction = transaction
      }).catch(error => {
        this.$root.$emit('showError', error.message)
        this.doGoBackToAllTransaction()
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doFulfillProduct (product, value) {
      this.isLoadingProducts = true

      this.$services.transaction.doFulfillProduct(this.transaction.uuid, product, value).then(_ => {

      }).catch(error => {
        this.$root.$emit('showError', error.message)

        this.doLoad(this.transaction.uuid)
      }).finally(_ => {
        this.isLoadingProducts = false
      })
    },
    doFulfillContact (value) {
      this.isLoadingCustomerContact = true

      this.$services.transaction.doFulfillContact(this.transaction.uuid, value).then(_ => {

      }).catch(error => {
        this.$root.$emit('showError', error.message)

        this.doLoad(this.transaction.uuid)
      }).finally(_ => {
        this.isLoadingCustomerContact = false
      })
    },
    doFulfillNoteAddition () {
      if (this.$refs.noteForm.validate()) {
        this.isLoadingNotes = true

        this.$services.transaction.doFulfillNoteAddition(this.transaction.uuid, this.note).then(notes => {
          this.note = null
          this.transaction.notes = notes
        }).catch(error => {
          this.$root.$emit('showError', error.message)

          this.doLoad(this.transaction.uuid)
        }).finally(_ => {
          this.isLoadingNotes = false
          this.$refs.noteForm.resetValidation()
        })
      }
    },
    doOpenRefundOptions () {
      this.$refs.refundOptions.doOpen(this.transaction.uuid)
    },
    doEmailTransaction () {
      this.isLoadingEmail = true

      this.$services.transaction.doEmail(this.transaction.uuid).then(_ => {
        alert('Success')
      }).catch(error => {
        this.$root.$emit('showError', error.message)

        this.doLoad(this.transaction.uuid)
      }).finally(_ => {
        this.isLoadingEmail = false
      })
    },
    doGoBackToAllTransaction () {
      this.$router.replace({ name: 'admin-transactions' })
    }
  },
  computed: {
    statusColor () {
      let color
      switch (this.transaction.paymentStatus) {
        case 'requires_payment_method':
          color = 'error'
          break
        case 'requires_confirmation':
          color = 'warning'
          break
        case 'requires_action':
          color = 'warning'
          break
        case 'processing':
          color = 'purple'
          break
        case 'succeeded':
          color = 'success'
          break
        case 'canceled':
          color = 'grey'
          break
        case 'requires_capture':
          color = 'blue'
          break
        case 'captured':
          color = 'teal'
          break
        default:
          color = 'grey'
      }
      return color
    },
    statusTitle () {
      let text
      switch (this.transaction.paymentStatus) {
        case 'requires_payment_method':
          text = 'Payment Failed'
          break
        case 'processing':
          text = 'Processing'
          break
        case 'succeeded':
          text = 'Success'
          break
        case 'canceled':
          text = 'Cancelled'
          break
        default:
          text = this.transaction.paymentStatus
      }
      return text
    },
    customerContactSatisfaction () {
      if (!this.transaction.contactedCustomer) {
        return this.$moment().subtract(2, 'days').toDate() >= new Date(this.transaction.createdAt)
      } else {
        return false
      }
    }
  },
  mounted () {
    const params = this.$route.params

    if ('uuid' in params) {
      this.userRole = this.$store.getters.doGetRole

      this.doLoad(params.uuid)
    } else {
      this.doGoBackToAllTransaction()
    }
  }
}
</script>

<style scoped>
.lastSixUuid::after {
  content: attr(data-end);
  font-weight: bolder;
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 16px !important;
}
</style>
