<template>
  <span>
    <v-overlay :value="isLoading" z-index="500">
      <div class="text-center pa-8 rounded-lg" style="max-width: 800px;">
         <v-progress-circular indeterminate color="white"></v-progress-circular>
      </div>
    </v-overlay>
    <v-dialog
      v-model="isOpen"
      persistent
      max-width="800"
    >
    <v-card>
      <v-card-title class="text-h5">
        Refund options
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="doRefund" ref="refundForm">
            <v-text-field prepend-inner-icon="fa-dollar-sign" label="Cost" v-model.number="refundAmount" outlined persistent-hint :hint="`The amount to refund, with a maximum of $${(transaction.maxRefundCents / 100).toFixed(2)}`" :rules="inputs.rules.simplyRequiredNumbers"></v-text-field>
            <v-textarea outlined rows="3" label="Refund Reason" v-model="refund.reason" :rules="inputs.rules.simplyRequired" persistent-hint hint="This information will be sent to the customers bank, please be concise."></v-textarea>
          <div class="mt-3">
            <v-btn @click="doClose">Cancel</v-btn>
            <v-btn type="submit" color="error" style="float: right;">Refund</v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
  </span>
</template>

<script>
export default {
  name: 'viewRefundOptions',
  data: () => ({
    isLoading: false,
    isOpen: false,
    transaction: {
      uuid: null,
      paymentIntentId: null,
      clientSecret: null,
      customerId: null,
      customer: {
        firstName: null,
        lastName: null,
        fullName: null,
        email: null,
        phoneNumber: null
      },
      products: [],
      cents: 0,
      paymentStatus: null,
      contactedCustomer: false,
      notes: null,
      createdAt: null,
      maxRefundCents: null
    },
    refund: {
      cents: null,
      reason: null
    }
  }),
  computed: {
    refundAmount: {
      get: function () {
        return (this.refund.cents / 100)
      },
      set: function (newValue) {
        let typedValue = newValue * 100

        if (typedValue > this.transaction.maxRefundCents) {
          typedValue = this.transaction.maxRefundCents
        }

        this.refund.cents = typedValue
      }
    }
  },
  methods: {
    doOpen (uuid) {
      this.isLoading = true

      this.$services.transaction.doReadInternal(uuid).then(transaction => {
        this.transaction = transaction

        this.isOpen = true

        this.$nextTick(() => {
          this.$refs.refundForm.resetValidation()
        })
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doRefund () {
      if (this.$refs.refundForm.validate() && this.refund.cents <= this.transaction.maxRefundCents) {
        this.isLoading = true

        this.$services.transaction.doProcessRefund(this.transaction.uuid, this.refund.cents, this.refund.reason).then(_ => {
          this.doClose()
        }).catch(error => {
          this.$root.$emit('showError', error.message)
        }).finally(_ => {
          this.isLoading = false
        })
      } else if (this.refund.cents >= this.transaction.maxRefundCents) {
        this.$root.$emit('showWarning', `You can only refund up to $${this.transaction.maxRefundCents / 100}!`)
      }
    },
    doClose () {
      this.$emit('doLoad')
      this.isOpen = false
    }
  }
}
</script>

<style scoped>

</style>
